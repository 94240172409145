@use '../_colors.scss';
@import "../variables";

footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2em;
  width: 100%; /* Ensure the footer spans the full width of the container */
  height: 500px; /* Adjust height as needed */
  background: no-repeat center bottom / 100% auto url('../../../public/footer.svg');

  p {
    font-size: 1rem;
    order: 1;
    margin: 0 1rem;
    color: colors.$white;
  }

  @media screen and (min-width: 500px) {
    flex-direction: row;
    align-items: end;
    justify-content: space-between;

    p {
      order: unset;
    }
  }
}

.social-links {
  display: inline-flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0 1rem;

  li {
    a {
      background-image: none;
      display: flex;
      align-items: center;

      span {
        margin-left: 0.5em;
        font-size: 1.15rem;
      }
    }

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  svg {
    width: 20px;
  }
}